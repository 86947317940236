import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw, { vwDesktop, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

import { Body } from 'src/styles/Body.styled';

const Hero = (props) => {
  const { loggedIn, handleChange } = props;

  return (
    <Root loggedIn={loggedIn}>
      <Image
        active={!loggedIn}
        bg={require('src/assets/images/arris/Hero.jpg')}
      ></Image>

      <Wrapper active={!loggedIn}>
        <ArrisLogo src={require('src/assets/images/arris/Logo-Arris.svg')} />
        <div>
          {!loggedIn ? (
            <TitleWrapper active={!loggedIn}>
              <StyledH1 active={!loggedIn}>
                Welcome to the
                <br />
                Arris Residences
                <br />
                Sales Portal
              </StyledH1>
            </TitleWrapper>
          ) : (
            <>
              <TitleWrapper className="offwhite" active={!loggedIn}>
                <h2>EXCEPTIONAL TIME</h2>
                <h2>EXCEPTIONAL HOMES</h2>
                <h2>EXCEPTIONAL PRICE</h2>
              </TitleWrapper>
            </>
          )}
          <Login active={!loggedIn}>
            <LabelBody>Please enter your password to access:</LabelBody>
            <div>
              <Input
                type="password"
                autoComplete="off"
                placeholder="Enter password"
                onChange={(e) => handleChange(e)}
              />
              <Icon src={require('src/assets/images/hero-global/arrow.svg')} />
            </div>
          </Login>
          <Paragraph className="offwhite" active={loggedIn}>
            As a Bosa Development VIP, you’re invited to be amongst the first to
            purchase at Arris Residences, which is set to be this year’s most
            anticipated launch of new condo homes in Downtown Calgary!
            <br />
            <br />
            Explore the VIP Sales Portal to learn more; updated daily to provide
            you with the most current sales information. Get in before it’s gone
            - this advanced offering will be below market prices for a limited
            time only!
          </Paragraph>
        </div>
      </Wrapper>
    </Root>
  );
};

export default Hero;

Hero.propTypes = {
  loggedIn: PropTypes.bool,
  handleChange: PropTypes.func,
};

const Root = styled.div`
  width: 100%;
  position: relative;
  ${vw('padding-bottom', 40, 40, 0)}

  display: flex;
  flex-direction: column;
  /* justify-content: center; */

  @media ${media.desktop} {
    flex-direction: row-reverse;
    /* align-items: center; */
  }
  background-color: ${(props) => props.theme.color.black};
`;

const Wrapper = styled.div`
  /* justify-self: flex-start; */
  /* align-self: center; */
  /* width: 50vw; */
  ${vw('padding-top', 40, 80, 120)}
  ${(props) => vw('padding-left', 20, 40, !props.active ? 220 : 160)}
  ${vw('padding-bottom', 66, 105, 134)}
  display: flex;
  flex-direction: column;
  @media ${media.desktop} {
    width: 50vw;
    justify-content: space-between;
  }
`;

const StyledH1 = styled.h1`
  text-transform: uppercase;
  ${vw('margin-top', 40, 80, 0)}
  ${(props) => vw('margin-bottom', 20, 40, !props.active ? 40 : 90)}

  font-family: "azo-sans-web";
  font-weight: 300;
  color: ${(props) => props.theme.color.offWhite};
  ${vw('font-size', 24, 36, 36)}
`;

const Login = styled.div`
  position: relative;

  display: ${(props) => (props.active ? 'block' : 'none')};
`;

const Input = styled.input`
  background-color: ${({ theme }) => theme.color.offWhite};
  color: ${({ theme }) => theme.color.black};
  outline: none;
  border: 3px solid ${({ theme }) => theme.color.grey};
  font-family: 'azo-sans-web';
  font-weight: 300;
  ${vw('font-size', 12, 14, 14)}
  ${vw('line-height', 20, 24, 24)}
    ${vw('letter-spacing', 0.5, 0.5, 0.5)}
    ${vw('height', 50, 60, 60)}
    ${vw('width', 225, 380, 380)}
    /* ${vw('padding-right', 16, 16)} */
    ${vw('padding-left', 16, 16)}
`;

const Image = styled.div`
  position: relative;
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-position: bottom;
  width: 100vw;
  height: ${vwMobile(218)};

  @media ${media.tablet} {
    width: 100vw;
    height: ${vwTablet(523)};
  }

  @media ${media.desktop} {
    height: ${(props) => (props.active ? '100vh' : vwDesktop(912))};
    /* width: ${vwDesktop(818)}; */
    width: 50vw;
    height: 100vh;
  }
`;

const ArrisLogo = styled.img`
  ${vw('width', 150, 219 ,219)}
  ${vw('height', 80,131,131)}
`;

const Paragraph = styled.p`
  ${vw('width', 267, 576, 476)};
  ${vw('font-size', 12, 14, 16)}
  display: ${(props) => (props.active ? 'block' : 'none')};
`;

const Icon = styled.img`
  z-index: 1;
  position: absolute;
  bottom: 0;
  ${vw('bottom', 16, 19)}
  ${vw('left', 241, 400)}
  ${vw('width', 32, 45)}
`;

const LabelBody = styled(Body)`
  ${vw('margin-bottom', 8, 8, 8)}
`;

const TitleWrapper = styled.div`
  ${(props) => vw('margin-bottom', 20, 40, !props.active ? 40 : 90)}
  ${vw('margin-top', 40, 80, 110)}
  text-transform: uppercase;

  h2 {
    ${vw('font-size', 24, 48, 38)}
    ${vw('letter-spacing', 2.4, 4.8, 4.8)}
  }
`;
