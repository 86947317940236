import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import content from 'src/data/gallery';

import IconButton from 'src/components/IconButton';
import ImageFrame from 'src/components/ImageFrame';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import { Section } from 'src/styles/Section.styled';
import { SectionTitle } from 'src/styles/SectionTitle.styled';
import { SubHeader } from 'src/styles/SubHeader.styled';

const Gallery = (props) => {
  return (
    <Section white>
      <CustomSectionTitle dark>Gallery</CustomSectionTitle>
      <ButtonWrapper>
        <IconButton
          socialmedia
          darkBtn
          // href={`https://portal.arrisresidences.ca/wWoRLzirlK/media_assets.zip`}
          href="/gallery/media_assets.zip"
        >
          Download All Images
        </IconButton>
      </ButtonWrapper>

      {content.items.map((item, index) => (
        <SectionWrapper key={index}>
          <CustomSubHeader dark>{item.title}</CustomSubHeader>
          <GalleryWrapper>
            {item.images.map((tile, index) => (
              <ImageFrame
                dark
                key={index}
                image={tile.image}
                fileName={`/gallery/${tile.file}`}
              />
            ))}
          </GalleryWrapper>
        </SectionWrapper>
      ))}
    </Section>
  );
};

Gallery.propTypes = {};

export default Gallery;

const CustomSectionTitle = styled(SectionTitle)`
  /* ${vw('top', -20, 160, 280)}
  ${vw('left', -200, -50, -60)} */
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  ${vw('margin-bottom', 45, 40, 80)}
  @media ${media.tablet} {
    justify-content: flex-end;
  }
`;

const CustomSubHeader = styled(SubHeader)`
  ${vw('margin-bottom', 10, 20, 40)}
  ${vw('margin-top', 40, 80, 0)}
`;

const GalleryWrapper = styled.div`
  ${vw('margin-left', 0, -10, -20)}

  display: inline-grid;
  grid-template-columns: repeat(2, ${vwMobile(134)});
  ${vw('grid-column-gap', 12, 20, 30)}
  ${vw('grid-row-gap', 20, 20, 30)}

  @media ${media.tablet} {
    grid-template-columns: repeat(3, ${vwTablet(180)});
  }

  @media ${media.desktop} {
    grid-template-columns: repeat(4, ${vwDesktop(348)});
  }
`;

const SectionWrapper = styled.div`
  & + & {
    ${vw('margin-top', 40, 80, 120)}
  }
`;
