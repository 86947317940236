const videos = {
  items: [
    [
      {
        source: require('src/assets/images/arris/video/video-retail.mp4'),

        download: '/downloads/video/video-retail.mp4',
      },
    ],
  ],
  // buttons: [
  //   [
  //     {
  //       text: 'Download Video',
  //       link: 'google.com',
  //       download: true,
  //       underline: false,
  //     },
  //   ],
  // ],
};

export default videos;
