export default {
  items: [
    {
      title: 'Exterior',
      images: [
        {
          image: require('src/assets/images/arris/gallery/Exterior/Exterior_1@2x.jpg'),
          file: 'Exterior/Exterior_1@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Exterior/Exterior_2@2x.jpg'),
          file: 'Exterior/Exterior_2@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Exterior/Exterior_3@2x.jpg'),
          file: 'Exterior/Exterior_3@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Exterior/Exterior_4@2x.jpg'),
          file: 'Exterior/Exterior_4@2x.jpg',
        },
      ],
    },
    {
      title: 'interior (2-bed)',
      images: [
        {
          image: require('src/assets/images/arris/interior-2-bed/interior-2b-1@1.5x.png'),
          file: '/interior-2-bed/interior-2b-1.jpg',
        },
        {
          image: require('src/assets/images/arris/interior-2-bed/interior-2b-2@1.5x.png'),
          file: '/interior-2-bed/interior-2b-2.jpg',
        },
        {
          image: require('src/assets/images/arris/interior-2-bed/interior-2b-3@1.5x.png'),
          file: '/interior-2-bed/interior-2b-3.jpg',
        },
        {
          image: require('src/assets/images/arris/interior-2-bed/interior-2b-4@1.5x.png'),
          file: '/interior-2-bed/interior-2b-4.jpg',
        },
        {
          image: require('src/assets/images/arris/interior-2-bed/interior-2b-5@1.5x.png'),
          file: '/interior-2-bed/interior-2b-5.jpg',
        },
        {
          image: require('src/assets/images/arris/interior-2-bed/interior-2b-6@1.5x.png'),
          file: '/interior-2-bed/interior-2b-6.jpg',
        },
        {
          image: require('src/assets/images/arris/interior-2-bed/interior-2b-7@1.5x.png'),
          file: '/interior-2-bed/interior-2b-7.jpg',
        },
        {
          image: require('src/assets/images/arris/interior-2-bed/interior-2b-8@1.5x.png'),
          file: '/interior-2-bed/interior-2b-8.jpg',
        },
        {
          image: require('src/assets/images/arris/interior-2-bed/interior-2b-9@1.5x.png'),
          file: '/interior-2-bed/interior-2b-9.jpg',
        },
        {
          image: require('src/assets/images/arris/interior-2-bed/interior-2b-10@1.5x.png'),
          file: '/interior-2-bed/interior-2b-10.jpg',
        },
      ],
    },
    {
      title: 'interior (2-bed+den)',
      images: [
        {
          image: require('src/assets/images/arris/interior-2-bed-den/interior-2b-den-1@1.5x.png'),
          file: '/interior-2-bed-den/interior-2b-den-1.jpg',
        },
        {
          image: require('src/assets/images/arris/interior-2-bed-den/interior-2b-den-2@1.5x.png'),
          file: '/interior-2-bed-den/interior-2b-den-2.jpg',
        },
        {
          image: require('src/assets/images/arris/interior-2-bed-den/interior-2b-den-3@1.5x.png'),
          file: '/interior-2-bed-den/interior-2b-den-3.jpg',
        },
        {
          image: require('src/assets/images/arris/interior-2-bed-den/interior-2b-den-4@1.5x.png'),
          file: '/interior-2-bed-den/interior-2b-den-4.jpg',
        },
        {
          image: require('src/assets/images/arris/interior-2-bed-den/interior-2b-den-5@1.5x.png'),
          file: '/interior-2-bed-den/interior-2b-den-5.jpg',
        },
        {
          image: require('src/assets/images/arris/interior-2-bed-den/interior-2b-den-6@1.5x.png'),
          file: '/interior-2-bed-den/interior-2b-den-6.jpg',
        },
        {
          image: require('src/assets/images/arris/interior-2-bed-den/interior-2b-den-7@1.5x.png'),
          file: '/interior-2-bed-den/interior-2b-den-7.jpg',
        },
        {
          image: require('src/assets/images/arris/interior-2-bed-den/interior-2b-den-8@1.5x.png'),
          file: '/interior-2-bed-den/interior-2b-den-8.jpg',
        },
        {
          image: require('src/assets/images/arris/interior-2-bed-den/interior-2b-den-9@1.5x.png'),
          file: '/interior-2-bed-den/interior-2b-den-9.jpg',
        },
        {
          image: require('src/assets/images/arris/interior-2-bed-den/interior-2b-den-10@1.5x.png'),
          file: '/interior-2-bed-den/interior-2b-den-10.jpg',
        },
      ],
    },
    {
      title: 'Amenities',
      images: [
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_1@2x.jpg'),
          file: 'Amenities/Amenities_1@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_2@2x.jpg'),
          file: 'Amenities/Amenities_2@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_3@2x.jpg'),
          file: 'Amenities/Amenities_3@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_4@2x.jpg'),
          file: 'Amenities/Amenities_4@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_5@2x.jpg'),
          file: 'Amenities/Amenities_5@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_6@2x.jpg'),
          file: 'Amenities/Amenities_6@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_7@2x.jpg'),
          file: 'Amenities/Amenities_7@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_8@2x.jpg'),
          file: 'Amenities/Amenities_8@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_9@2x.jpg'),
          file: 'Amenities/Amenities_9@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_10@2x.jpg'),
          file: 'Amenities/Amenities_10@2x.jpg',
        },
      ],
    },
  ],
};
