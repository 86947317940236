import { createGlobalStyle } from 'styled-components';
import theme from './theme';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  body {
    font-family: 'Roboto';
    // This is a temporary fix to disable the iframe coming from react-hot-loader in development
    > iframe {
      display: none;
    }
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    color: ${theme.color.primary};
  }

  main {
    text-align: center;
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: 100%;
  }

  /* custom styles */
  h2 {
    font-family: 'NarzissRegular';
    font-weight: normal;
    font-style: normal;
  }

   p,ul,li {
    font-family: 'azo-sans-web';
    font-weight: 300;
  }

  p {
  }
  /* utlity styles */

  .white {
    color: ${theme.color.white};
  }

  .offwhite {
    color: ${theme.color.offWhite};
  }
`;
