import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

import { SubHeader } from 'src/styles/SubHeader.styled';
import { Body } from 'src/styles/Body.styled';

const Information = (props) => {
  return (
    <Root>
      <ArrisLogo src={require('src/assets/images/arris/Logo-Arris.svg')} />
      <Info>
        <Wrapper>
          <Title>
            Kevin Yeung
            <br />
            SALES MANAGER
          </Title>
          <TextWrapper>
            <TextLink large as="a" href="tel:403.669.6611" target="_blank">
              T 403.669.6611
            </TextLink>
            <TextLink
              large
              as="a"
              href="mailto:kevin@arrisresidences.ca"
              target="_blank"
            >
              E kevin@arrisresidences.ca
            </TextLink>
          </TextWrapper>
        </Wrapper>
        <Wrapper>
          <Title>
            Will Yeung
            <br />
            SALES MANAGER
          </Title>
          <TextWrapper>
            <TextLink large as="a" href="tel:403.978.3072" target="_blank">
              T 403.978.3072
            </TextLink>
            <TextLink
              large
              as="a"
              href="mailto:will@arrisresidences.ca"
              target="_blank"
            >
              E will@arrisresidences.ca
            </TextLink>
          </TextWrapper>
        </Wrapper>
      </Info>
      <BosaWrapper>
        <a href="https://bosadevelopment.com/" target="_blank">
          <BosaLogo src={require('src/assets/images/arris/Logo-Bosa.svg')} />
        </a>
      </BosaWrapper>
    </Root>
  );
};

Information.propTypes = {};

export default Information;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('margin-bottom', 40, 93, 94)}

  @media ${media.tablet} {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  @media ${media.desktop} {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const ArrisLogo = styled.img`
  ${vw('width', 140, 219, 219)}
  ${vw('margin-bottom', 0, 70, 0)}
  ${vw('margin-right', 0, 0, 160)}
`;

const BosaLogo = styled.img`
  ${vw('width', 140, 219, 219)}
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  ${vw('gap', 40, 100, 119)}
  @media ${media.tablet} {
    flex-direction: row;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  ${vw('margin-top', 40, 0, 0)}

  @media ${media.tablet} {
    align-items: flex-start;
    text-align: left;

    &:last-of-type {
      /* ${vw('margin-left', 0, 96, 174)} */
    }
  }
`;

const Title = styled(SubHeader)``;

const TextLink = styled(Body)`
  text-decoration: underline;
  font-weight: 400;

  @media ${media.desktop} {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${vw('margin-top', 10, 20, 40)}
`;

const BosaWrapper = styled.div`
  ${vw('margin-top', 70, 70, 0)}
  ${vw('margin-left', 0, 0, 135)}

  @media ${media.desktop} {
    align-self: flex-end;
  }
`;
