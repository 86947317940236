export default {
  downloads: [
    {
      text: '1 Bed',
      file: 'downloads/floorplans/1_Bed_Plans.zip',
    },
    {
      text: '2 Bed',
      file: 'downloads/floorplans/2_Bed_Plans.zip',
    },
    {
      text: '3 Bed',
      file: 'downloads/floorplans/3_Bed_Plans.zip',
    },
  ],
  floorplans: [
    {
      unit: 'B1',
      bed: 1,
      bath: 1,
      den: 1,
      interior: '695',
    },
    {
      unit: 'B2',
      bed: 1,
      bath: 1,
      den: 1,
      interior: '698',
    },
    {
      unit: 'B3',
      bed: 1,
      bath: 1,
      den: 1,
      interior: '701',
    },
    {
      unit: 'C1',
      bed: 2,
      bath: 2,
      interior: '968',
    },
    {
      unit: 'C2',
      bed: 2,
      bath: 2,
      interior: '1,081',
    },
    {
      unit: 'C3',
      bed: 2,
      bath: 2,
      interior: '1,109',
    },
    {
      unit: 'C4',
      bed: 2,
      bath: 2,
      interior: '1,194',
    },
    {
      unit: 'D1',
      bed: 2,
      bath: 2,
      den: true,
      interior: '1,089',
    },
    {
      unit: 'D2',
      bed: 2,
      bath: 2,
      den: true,
      interior: '1,249',
    },
    {
      unit: 'D3',
      bed: 2,
      bath: 2,
      den: true,
      interior: '1,276',
    },
    {
      unit: 'D4',
      bed: 2,
      bath: 2,
      den: true,
      interior: '1,312.6',
    },
    {
      unit: 'PH1',
      bed: 2,
      bath: 2,
      interior: '1,364',
    },
    {
      unit: 'PH2',
      bed: 2,
      bath: 2,
      interior: '1,592',
      den: true,
      media: true,
    },
    {
      unit: 'PH3',
      bed: 3,
      bath: 3,
      interior: '1,646',
    },
    {
      unit: 'PH4',
      bed: 3,
      bath: 2,
      interior: '1,757',
    },
    {
      unit: 'PH5',
      bed: 2,
      bath: 2,
      den: true,
      interior: '1,502',
    },
    {
      unit: 'SPH1',
      bed: 2,
      bath: 2,
      interior: '1,364',
    },
    {
      unit: 'SPH2',
      bed: 2,
      bath: 2,
      interior: '1,592',
      den: true,
      media: true,
    },
    {
      unit: 'SPH3',
      bed: 3,
      bath: 3,
      interior: '1,646',
    },
    {
      unit: 'SPH4',
      bed: 3,
      bath: 2,
      interior: '1,757',
    },
    {
      unit: 'SPH5',
      bed: 2,
      bath: 2,
      den: true,
      interior: '1,502',
    },
  ],
};
