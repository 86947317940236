import React from 'react';
import { forwardRef, ForwardedRef, LegacyRef } from 'react';
import styled from 'styled-components';

// forwardRef
// https://reactjs.org/docs/forwarding-refs.html

const Player = forwardRef((props, ref) => {
  const { url, poster, playing, setPlaying } = props;

  const handlePlayVideo = () => {
    if (playing) {
      void ref.current?.pause();
    } else {
      void ref.current?.play();
    }
    setPlaying(!playing);
  };

  return (
    <Root onClick={() => handlePlayVideo()}>
      <video
        // @ts-ignore
        ref={
          // @ts-ignore
          ref
        }
        poster={poster}
        playsInline
        controls={playing ? true : false}
      >
        <source src={url} type="video/mp4" />
      </video>
    </Root>
  );
});

export default Player;

const Root = styled.div`
  video {
    width: 100%;
  }
  width: 100%;
  cursor: pointer;
`;
