import React from 'react';

import styled from 'styled-components';

import Grid from 'src/components/Grid';
import Video from './Video';
import { SubHeader } from '../../../../../styles/SubHeader.styled';

import videos from 'src/data/videos';

import vw from 'src/styles/utils';

const Videos = ({ className }) => {
  return (
    <>
      <Grid
        columns={[1, 1, videos.items.length > 1 ? 2 : 1]}
        gap={[20, 40, videos.items.length > 1 ? 40 : 0]}
      >
        {videos.items[0].map((video) => (
          <Video
            multiple={videos.items.length > 1 ? true : false}
            data={video}
          />
        ))}
      </Grid>
    </>
  );
};

export default Videos;

const Root = styled.div``;

const Spacer = styled.div`
  ${vw('height', 150, 500, 700)}
`;
